
(function($){

	"use strict";
	$('.collapse.navbar-collapse').removeClass('active');
	// Header Sticky
	$(window).on('scroll',function() {
		if ($(this).scrollTop() > 120){  
			$('.navbar-area').addClass("is-sticky");
		}
		else{
			$('.navbar-area').removeClass("is-sticky");
		}
	});

	// Navbar Toggler
	$(".navbar-toggler").off().on('click',  function() {
		if($('.collapse.navbar-collapse').hasClass('active'))
			$('.collapse.navbar-collapse').removeClass('active');
		else
			$('.collapse.navbar-collapse').addClass('active');
	
		
	});
	// Sidebar Modal
	// $(".burger-menu").on('click',  function() {
	// 	$('.sidebar-modal').toggleClass('active');
	// });
	// $(".sidebar-modal-close-btn").on('click',  function() {
	// 	$('.sidebar-modal').removeClass('active');
	// });

	// Search Popup JS
	$('.close-btn').on('click',function() {
		$('.search-overlay').fadeOut();
		$('.search-btn').show();
		$('.close-btn').removeClass('active');
	});
	$('.search-btn').on('click',function() {
		$(this).hide();
		$('.search-overlay').fadeIn();
		$('.close-btn').addClass('active');
	});

	// Home Sliders
	// $('.home-sliders').owlCarousel({
	// 	loop:true,
	// 	margin:0,
	// 	nav:false,
	// 	mouseDrag: true,
	// 	items:1,
	// 	autoHeight:true,
	// 	dots: true,
	// 	autoplay: false,
	// 	smartSpeed: 500,
	// 	autoplayHoverPause: true,
	// 	navText: [
	// 		"<i class='flaticon-left'></i>",
	// 		"<i class='flaticon-right-1'></i>",
	// 	],
	// });
	// $(".home-sliders").on("translate.owl.carousel", function(){
	// 	$(".main-banner-content span, .main-banner-content h1, .main-banner-content .typewrite").removeClass("animated fadeInUp").css("opacity", "0");
	// 	$(".main-banner-content p").removeClass("animated fadeInDown").css("opacity", "0");
	// 	$(".main-banner-content a").removeClass("animated fadeInDown").css("opacity", "0");
	// 	$(".banner-image").removeClass("animated slideInUp").css("opacity", "0");
	// });
	// $(".home-sliders").on("translated.owl.carousel", function(){
	// 	$(".main-banner-content span, .main-banner-content h1, .main-banner-content .typewrite").addClass("animated fadeInUp").css("opacity", "1");
	// 	$(".main-banner-content p").addClass("animated fadeInDown").css("opacity", "1");
	// 	$(".main-banner-content a").addClass("animated fadeInDown").css("opacity", "1");
	// 	$(".banner-image").addClass("animated slideInUp").css("opacity", "1");
	// });

	// $('.team-slider').owlCarousel({
	// 	loop:true,
	// 	margin:10,
	// 	nav:true,
	// 	responsive:{
	// 		0:{
	// 			items:1
	// 		},
	// 		600:{
	// 			items:3
	// 		},
	// 		1000:{
	// 			items:5
	// 		}
	// 	}
	// })
	//team slider

	$('.team-slider').owlCarousel({
		loop: false,
		nav: true,
		dots: false,
		autoplayHoverPause: true,
		autoplay: true,
		smartSpeed: 1000,
		margin: 0,
		navText: [
			"<i class='flaticon-left'></i>",
			"<i class='flaticon-right'></i>"
		],
		responsive: {
			0: {
				items: 3
			},
			576: {
				items: 3
			},
			768: {
				items: 5
			},
			1200: {
				items: 7
			}
		}
	});

	// Clients Slider
	$('.clients-slider').owlCarousel({
		loop: true,
		nav: false,
		dots: true,
		autoplayHoverPause: true,
		autoplay: true,
		smartSpeed: 1000,
		margin: 0,
		navText: [
			"<i class='flaticon-left'></i>",
			"<i class='flaticon-right'></i>"
		],
		responsive: {
			0: {
				items: 1
			},
			576: {
				items: 1
			},
			768: {
				items: 1
			},
			1200: {
				items: 1
			}
		}
	});

	// Partner-slider
	// $('.partner-slider').owlCarousel({
	// 	loop: true,
	// 	nav: false,
	// 	dots: false,
	// 	smartSpeed: 2000,
	// 	margin: 30,
	// 	autoplayHoverPause: true,
	// 	autoplay: false,
	// 	responsive: {
	// 		0: {
	// 			items: 2
	// 		},
	// 		576: {
	// 			items: 3
	// 		},
	// 		768: {
	// 			items: 4
	// 		},
	// 		1200: {
	// 			items: 5
	// 		}
	// 	}
	// });

	// Tabs
	(function ($) {
		$('.tab ul.tabs').addClass('active').find('> li:eq(0)').addClass('current');
		$('.tab ul.tabs li a').on('click', function (g) {
			var tab = $(this).closest('.tab'), 
			index = $(this).closest('li').index();
			tab.find('ul.tabs > li').removeClass('current');
			$(this).closest('li').addClass('current');
			tab.find('.tab_content').find('div.tabs_item').not('div.tabs_item:eq(' + index + ')').slideUp();
			tab.find('.tab_content').find('div.tabs_item:eq(' + index + ')').slideDown();
			g.preventDefault();
		});
	})(jQuery);
	
	// Odometer JS
	$('.odometer').appear(function(e) {
		var odo = $(".odometer");
		odo.each(function() {
			var countNumber = $(this).attr("data-count");
			$(this).html(countNumber);
		});
	});

	// Skill
	jQuery('.skill-bar').each(function() {
		jQuery(this).find('.progress-content').animate({
		width:jQuery(this).attr('data-percentage')
		},2000);
		
		jQuery(this).find('.progress-number-mark').animate(
		{left:jQuery(this).attr('data-percentage')},
		{
			duration: 2000,
			step: function(now, fx) {
			var data = Math.round(now);
			jQuery(this).find('.percent').html(data + '%');
			}
		});  
	});

	// Popup Video
	// $('.popup-youtube').magnificPopup({
	// 	disableOn: 320,
	// 	type: 'iframe',
	// 	mainClass: 'mfp-fade',
	// 	removalDelay: 160,
	// 	preloader: false,
	// 	fixedContentPos: false
	// });

	// Tabs
	(function ($) {
		$('.tab ul.tabs').addClass('active').find('> li:eq(0)').addClass('current');
		$('.tab ul.tabs li a').on('click', function (g) {
			var tab = $(this).closest('.tab'), 
			index = $(this).closest('li').index();
			tab.find('ul.tabs > li').removeClass('current');
			$(this).closest('li').addClass('current');
			tab.find('.tab_content').find('div.tabs_item').not('div.tabs_item:eq(' + index + ')').slideUp();
			tab.find('.tab_content').find('div.tabs_item:eq(' + index + ')').slideDown();
			g.preventDefault();
		});
	})(jQuery);

	// Count Time 
	function makeTimer() {
		var endTime = new Date("September 30, 2020 17:00:00 PDT");			
		var endTime = (Date.parse(endTime)) / 1000;
		var now = new Date();
		var now = (Date.parse(now) / 1000);
		var timeLeft = endTime - now;
		var days = Math.floor(timeLeft / 86400); 
		var hours = Math.floor((timeLeft - (days * 86400)) / 3600);
		var minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600 )) / 60);
		var seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));
		if (hours < "10") { hours = "0" + hours; }
		if (minutes < "10") { minutes = "0" + minutes; }
		if (seconds < "10") { seconds = "0" + seconds; }
		$("#days").html(days + "<span>Days</span>");
		$("#hours").html(hours + "<span>Hours</span>");
		$("#minutes").html(minutes + "<span>Minutes</span>");
		$("#seconds").html(seconds + "<span>Seconds</span>");
	}
	setInterval(function() { makeTimer(); }, 1000);

	// Go to Top
	$(function(){
		// Scroll Event
		$(window).on('scroll', function(){
			var scrolled = $(window).scrollTop();
			if (scrolled > 600) $('.go-top').addClass('active');
			if (scrolled < 600) $('.go-top').removeClass('active');
		});  
		// Click Event
		$('.go-top').on('click', function() {
			$("html, body").animate({ scrollTop: "0" },  10);
		});
	});

    
	// WOW JS
	$(window).on ('load', function (){
        if ($(".wow").length) { 
            let wow = new WOW({
            boxClass:     'wow',      // animated element css class (default is wow)
            animateClass: 'animated', // animation css class (default is animated)
            offset:       20,          // distance to the element when triggering the animation (default is 0)
            mobile:       true, // trigger animations on mobile devices (default is true)
            live:         true,       // act on asynchronously loaded content (default is true)
          });
          wow.init();
        }
    });
	/**
* Template Name: TheEvent
* Updated: Mar 10 2023 with Bootstrap v5.2.3
* Template URL: https://bootstrapmade.com/theevent-conference-event-bootstrap-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/
(function() {
	"use strict";
  
	/**
	 * Easy selector helper function
	 */
	const select = (el, all = false) => {
	  el = el.trim()
	  if (all) {
		return [...document.querySelectorAll(el)]
	  } else {
		return document.querySelector(el)
	  }
	}
  
	/**
	 * Easy event listener function
	 */
	const on = (type, el, listener, all = false) => {
	  let selectEl = select(el, all)
	  if (selectEl) {
		if (all) {
		  selectEl.forEach(e => e.addEventListener(type, listener))
		} else {
		  selectEl.addEventListener(type, listener)
		}
	  }
	}
  
	/**
	 * Easy on scroll event listener 
	 */
	const onscroll = (el, listener) => {
	  el.addEventListener('scroll', listener)
	}
  
	/**
	 * Navbar links active state on scroll
	 */
	let navbarlinks = select('#navbar .scrollto', true)
	const navbarlinksActive = () => {
	  let position = window.scrollY + 200
	  navbarlinks.forEach(navbarlink => {
		if (!navbarlink.hash) return
		let section = select(navbarlink.hash)
		if (!section) return
		if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
		  navbarlink.classList.add('active')
		} else {
		  navbarlink.classList.remove('active')
		}
	  })
	}
	window.addEventListener('load', navbarlinksActive)
	onscroll(document, navbarlinksActive)
  
	/**
	 * Scrolls to an element with header offset
	 */
	const scrollto = (el) => {
	  let header = select('#header')
	  let offset = header.offsetHeight
  
	  if (!header.classList.contains('header-scrolled')) {
		offset -= 20
	  }
  
	  let elementPos = select(el).offsetTop
	  window.scrollTo({
		top: elementPos - offset,
		behavior: 'smooth'
	  })
	}
  
	/**
	 * Toggle .header-scrolled class to #header when page is scrolled
	 */
	let selectHeader = select('#header')
	if (selectHeader) {
	  const headerScrolled = () => {
		if (window.scrollY > 100) {
		  selectHeader.classList.add('header-scrolled')
		} else {
		  selectHeader.classList.remove('header-scrolled')
		}
	  }
	  window.addEventListener('load', headerScrolled)
	  onscroll(document, headerScrolled)
	}
  
	/**
	 * Back to top button
	 */
	let backtotop = select('.back-to-top')
	if (backtotop) {
	  const toggleBacktotop = () => {
		if (window.scrollY > 100) {
		  backtotop.classList.add('active')
		} else {
		  backtotop.classList.remove('active')
		}
	  }
	  window.addEventListener('load', toggleBacktotop)
	  onscroll(document, toggleBacktotop)
	}
  
	/**
	 * Mobile nav toggle
	 */
	on('click', '.mobile-nav-toggle', function(e) {
	  select('#navbar').classList.toggle('navbar-mobile')
	  this.classList.toggle('bi-list')
	  this.classList.toggle('bi-x')
	})
  
	/**
	 * Mobile nav dropdowns activate
	 */
	on('click', '.navbar .dropdown > a', function(e) {
	  if (select('#navbar').classList.contains('navbar-mobile')) {
		e.preventDefault()
		this.nextElementSibling.classList.toggle('dropdown-active')
	  }
	}, true)
  
	/**
	 * Scrool with ofset on links with a class name .scrollto
	 */
	on('click', '.scrollto', function(e) {
	  if (select(this.hash)) {
		e.preventDefault()
  
		let navbar = select('#navbar')
		if (navbar.classList.contains('navbar-mobile')) {
		  navbar.classList.remove('navbar-mobile')
		  let navbarToggle = select('.mobile-nav-toggle')
		  navbarToggle.classList.toggle('bi-list')
		  navbarToggle.classList.toggle('bi-x')
		}
		scrollto(this.hash)
	  }
	}, true)
  
	/**
	 * Scroll with ofset on page load with hash links in the url
	 */
	window.addEventListener('load', () => {
	  if (window.location.hash) {
		if (select(window.location.hash)) {
		  scrollto(window.location.hash)
		}
	  }
	});
  
	/**
	 * Initiate glightbox
	 */
	// const glightbox = GLightbox({
	//   selector: '.glightbox'
	// });
  
	/**
	 * Gallery Slider
	 */
	// new Swiper('.gallery-slider', {
	//   speed: 400,
	//   loop: true,
	//   centeredSlides: true,
	//   autoplay: {
	// 	delay: 5000,
	// 	disableOnInteraction: false
	//   },
	//   slidesPerView: 'auto',
	//   pagination: {
	// 	el: '.swiper-pagination',
	// 	type: 'bullets',
	// 	clickable: true
	//   },
	//   breakpoints: {
	// 	320: {
	// 	  slidesPerView: 1,
	// 	  spaceBetween: 20
	// 	},
	// 	575: {
	// 	  slidesPerView: 2,
	// 	  spaceBetween: 20
	// 	},
	// 	768: {
	// 	  slidesPerView: 3,
	// 	  spaceBetween: 20
	// 	},
	// 	992: {
	// 	  slidesPerView: 5,
	// 	  spaceBetween: 20
	// 	}
	//   }
	// });
  
	/**
	 * Initiate gallery lightbox 
	 */
	// const galleryLightbox = GLightbox({
	//   selector: '.gallery-lightbox'
	// });
  
	/**
	 * Buy tickets select the ticket type on click
	 */
	// on('show.bs.modal', '#buy-ticket-modal', function(event) {
	//   select('#buy-ticket-modal #ticket-type').value = event.relatedTarget.getAttribute('data-ticket-type')
	// })
  
	/**
	 * Animation on scroll
	 */
	// window.addEventListener('load', () => {
	//   AOS.init({
	// 	duration: 1000,
	// 	easing: 'ease-in-out',
	// 	once: true,
	// 	mirror: false
	//   })
	// });
  
  })()

}(jQuery));